import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Partners = () => {
  const pageData = useStaticQuery(graphql`
    query {
      allContentfulPartner {
        edges {
          node {
            name
            logo {
              fluid(maxWidth: 200) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
          }
        }
      }
    }
  `)

  const data = pageData.allContentfulPartner.edges
  return (
    <section className="padding ptb-xs-40">
      <div className="container">
        <div className="row pb-50 pb-xs-30 text-center">
          <div className="col-md-12">
            <div className="section-title_home">
              <h2>
                Our <span>Partners</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="partners">
          <div className="container">
            <div className="row">
              {data &&
                data.map((partner, index) => (
                  <div
                    key={`partner-${index}`}
                    className="col-lg-2 col-md-3 col-sm-4 col-xs-6 partner-image"
                  >
                    <Img
                      fluid={partner.node.logo.fluid}
                      alt={partner.node.name}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partners
